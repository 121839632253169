import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Error = styled.div`
  font-family: Montserrat, sans-serif;
  color: ${props => props.theme.colors.error};
  font-size: 18px;
  text-align: center;
  margin-bottom: 1em;
  width: 100%;
`

const FormError = ({ submitting, error, ...props }) =>
  error && !submitting ? <Error {...props}>{error}</Error> : null

FormError.propTypes = {
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default FormError
