import { green } from '@material-ui/core/colors'
import DoneRoundedIconView from '@mui/icons-material/DoneRounded'
import React from 'react'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import styled, { css } from 'styled-components'
import { View } from 'components/layout'
import LoadingSection from 'components/status/Loading'
import { H3 } from 'components/typography'
import toDate from 'date-fns/toDate'
import PropTypes from 'prop-types'
import { media } from 'styled-bootstrap-grid'

// This global css must be imported in `pages/_app.js` since Next.js 11
// import './react-widgets-styles/import.scss'

const FormGroup = styled.div`
  position: relative;
  margin-bottom: 0.5em;
  width: 100%;

  ${media.sm`
    width: ${(props) => props.width};
  `};
`

const Label = styled.label`
  display: inline-block;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
  font-family: Montserrat, sans-serif;
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  margin-left: ${(props) => props.theme.spacing.md}px;
`

export const textInputCSS = css`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors[props.error ? 'error' : 'grayLight']};
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSizes.md}px;
  padding: ${(props) => props.theme.spacing.sm * 1.5}px;
  margin-bottom: ${(props) => props.theme.spacing.xs}px;
  width: 100%;

  &:focus {
    outline: ${(props) => props.theme.colors.gray};
  }

  ::placeholder {
    font-family: Montserrat, sans-serif;
    color: ${(props) => props.theme.colors.gray};
  }
`

const TextInput = styled.input`
  ${textInputCSS}
`

const StaticText = styled.div`
  padding: 0.6em;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  width: 100%;
`

const TextAreaInput = styled.textarea`
  ${textInputCSS}
  resize: none;
  line-height: 1.3;

  &:focus {
    outline: ${(props) => props.theme.colors.gray};
  }

  ::placeholder {
    font-family: Montserrat, sans-serif;
    color: ${(props) => props.theme.colors.gray};
    font-size: ${(props) => props.theme.fontSizes.md}px;
  }
`

// Select inputs don't have placeholder text, instead the default option
// is styled like the placeholders of the other inputs.
const SelectInput = styled.select`
  outline: none;
  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors[props.error ? 'error' : 'grayLight']};
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: ${(props) => props.theme.colors[props.value || !props.placeholder ? 'black' : 'gray']};
  padding: 8px 6px;
  margin-bottom: 4px;
  width: 100%;

  > option:first-child {
    ${(props) =>
      !props.placeholder
        ? ''
        : `
      font-family: Montserrat, sans-serif;
      color: ${(props) => props.theme.colors.black};
      font-size: 18px;
    `};
  }

  > option {
    font-family: Montserrat, sans-serif;
    color: ${(props) => props.theme.colors.black};
    font-size: 18px;
  }
`

const DatePickerInput = styled(DateTimePicker)`
  &.rw-widget {
    padding: 0;

    .rw-widget-container {
      border: 2px solid ${(props) => props.theme.colors[props.error ? 'error' : 'grayLight']};
      border-radius: 4px;
      padding: 0;
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      width: 100%;
    }

    .rw-input {
      padding: 8px 6px;
    }
  }
`

const Help = styled.p`
  margin: 0.3em 0 0 2px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 1.3;
  color: ${(props) => props.theme.colors.grayDark};
`

const Error = styled(Help)`
  color: ${(props) => props.theme.colors.error};
`

const LoadingView = styled(View)`
  position: absolute;
  top: -20px;
  right: -18px;
`

const VerifiedView = styled(View)`
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 4px;
  right: 4px;
`

const VerifiedText = styled(H3)`
  margin: 4px;
`

/**
 * An input component for use with `react-final-form`.
 */
const Input = ({
  input,
  meta,
  label,
  help,
  hideError,
  children,
  componentClass,
  width,
  inputRef,
  style,
  className,
  errorOnlyIfDirty,
  loading = false,
  isVerified = false,
  onChange,
  ...props
}) => {
  const { dirty, touched, submitFailed, error, submitError } = meta || {}
  const showError = (!errorOnlyIfDirty || dirty || submitFailed) && !hideError && touched && (submitError || error)
  const InputComp = componentClass || TextInput

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value)
    }
    input?.onChange(e)
  }

  return (
    <FormGroup width={width} className={className} style={style}>
      {label && <Label htmlFor={input.name}>{label}</Label>}
      <InputComp type="text" {...props} {...input} error={showError} ref={inputRef} onChange={handleChange}>
        {children}
      </InputComp>

      {loading && (
        <LoadingView>
          <LoadingSection size="sm" />
        </LoadingView>
      )}

      {isVerified && (
        <VerifiedView>
          <VerifiedText>Verified</VerifiedText>
          <DoneRoundedIconView fontSize="large" sx={{ color: green[500] }} />
        </VerifiedView>
      )}

      {showError && <Error>{showError}</Error>}
      {help && <Help>{help}</Help>}
    </FormGroup>
  )
}

Input.propTypes = {
  /** Provide an input component to override the default `TextInput` */
  componentClass: PropTypes.any,
  /** Set the width of the input group */
  width: PropTypes.string,
  /**
   * When auto-focusing the first component it will show a required validation error on blur; set
   * this to true and it will only show the error if the input is dirty or after a failed submit
   */
  errorOnlyIfDirty: PropTypes.bool,
}

Input.defaultProps = {
  width: '100%',
}

Input.Help = Help
Input.Label = Label
Input.Text = TextInput
Input.TextArea = TextAreaInput

Input.Select = ({ placeholder, children, ...props }) => (
  <SelectInput {...props} placeholder={placeholder}>
    {placeholder && <option value="">{placeholder}</option>}
    {children}
  </SelectInput>
)

Input.DateTimePicker = ({ dateFormat, value, onBlur, children, ...props }) => (
  <DatePickerInput {...props} value={toDate(value)} onBlur={() => onBlur(toDate(value))} format={dateFormat} />
)

Input.StaticText = ({ style, textStyle, ...props }) => (
  <FormGroup style={style}>
    <StaticText {...props} style={textStyle} />
  </FormGroup>
)

export default Input
