import React from 'react'
import styled from 'styled-components'
import { P } from '@components/typography'
import Spinner from './Spinner/Spinner'

const PaddedSection = styled.div`
  padding: ${(props) => props.padding ?? `10px`};
  min-height: ${(props) => props.minHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => props.marginTop};
`

const Message = styled(P)`
  margin-top: 10px;
`

const LoadingSection = ({ message, size, ...props }) => (
  <PaddedSection center {...props}>
    <Spinner size={size ?? 'lg'} />
    {message && <Message>{message}</Message>}
  </PaddedSection>
)

export default LoadingSection
