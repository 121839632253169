import get from 'lodash/get'

const isEmpty = (value) => value === undefined || value === null || value === ''

export const composeValidators =
  (...validators) =>
  (...args) =>
    validators.reduce((error, validator) => error || validator(...args), null)

export function required(value) {
  if (isEmpty(value)) {
    return 'Required field'
  }
}

export function cardRequired(value) {
  if (isEmpty(value)) {
    return 'Payment card required'
  }
}

export function email(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address'
  }
}

const validUsPhone = (value) => !/^\d{3}-\d{3}-\d{4}$/.test(value)
const validUkPhone = (value) => !/^\d{5}\s\d{6}$/.test(value)

export function usPhone(value) {
  if (!isEmpty(value) && validUsPhone(value)) {
    return 'Invalid US phone number'
  }
}

export function ukPhone(value) {
  if (!isEmpty(value) && validUkPhone(value)) {
    return 'Invalid UK phone number'
  }
}

export function usOrUkPhone(value) {
  if (!isEmpty(value) && !/^\d{10,13}$/.test(value)) {
    return 'Invalid phone number'
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`
    }
  }
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`
    }
  }
}

export const makeMaxWords =
  (errorMessage) =>
  (max) =>
  (value = '') => {
    const wordCount = value.split(' ').length
    if (!isEmpty(value) && wordCount > max) {
      return errorMessage || `Must be no more than ${max} words`
      // return errorMessage || `You're limited to ${max} words. Please shorten your question.`
    }
  }

export function greaterThan(min) {
  return (value) => {
    if (!isEmpty(value) && value <= min) {
      return `Must be greater than ${min}`
    }
  }
}

export function requiredIfSet(field, fieldName) {
  return (value, values) => {
    if (!value && get(values, field)) {
      return `Required if ${fieldName || field} is set`
    }
  }
}

export function equalTo(field, fieldName) {
  return (value, values) => {
    if (!isEmpty(value) && get(values, field) !== value) {
      return `Does not match ${fieldName || field}`
    }
  }
}

export function checkboxTrue(value) {
  if (!isEmpty(value) && value !== true) {
    return 'Terms & Conditions must be accepted to proceed'
  }
}

const mentoringQuestionMaxWords = (max) =>
  makeMaxWords(
    `You've exceeded the ${max} words limit, please refine your question to make it a bit more specific, thank you.`,
  )(max)

const containsURL = (text = '') => {
  const lowerCase = text.toLowerCase()

  return !!['www.', 'http://', 'https://', '.com', '.co.uk'].find((val) => lowerCase.includes(val))
}

const noURLs = (message) => (value) => {
  if (containsURL(value)) {
    return message
  }
}

export const mentoringQuestion = composeValidators(
  required,
  mentoringQuestionMaxWords(100),
  maxLength(5000),
  noURLs(
    'Your question seems to include a link/attachment. Per our guidelines, please remove any links/attachments before submitting your question. Thank you!',
  ),
)

const maxWords = (max) => makeMaxWords(`Must be under ${max} words`)(max)

export const testimonialValidation = composeValidators(required, maxWords(85))

export const requiredGreaterThanZero = composeValidators(required, greaterThan(0))
export const minLength2Required = composeValidators(required, minLength(2))
export const requiredEmail = composeValidators(required, email)
export const requiredUsPhone = composeValidators(required, usPhone)
export const requiredUkPhone = composeValidators(required, ukPhone)
export const requiredUsOrUkPhone = composeValidators(required, usOrUkPhone)
export const password = composeValidators(required, minLength(6))
export const requiredAnd = (validator) => composeValidators(required, validator)
export const requiredTrue = composeValidators(required, checkboxTrue)
export const requiredAndMaxChars = (maxChars) => requiredAnd(maxLength(maxChars))
export const requiredAndMinChars = (minChars) => requiredAnd(minLength(minChars))
export const maxLength100Required = requiredAndMaxChars(100)
export const maxLength150Required = requiredAndMaxChars(150)
